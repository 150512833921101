.suit-sherwani {
  display: flex;
  flex-direction: column;
  width: auto;
}

.suit-sherwani img {
  width: 60vw;
}

.suit-sherwani div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.suit-sherwani h3 {
  font-size: 1.3em;
}

.suit-sherwani img:nth-child(1) {
  transform: scaleX(-1);
}

.suit-sherwani h3:nth-child(2) {
  margin-right: 2em;
  font-size: 1.5em;
  color: #a17825;
}

.suit-sherwani h3:nth-child(1) {
  margin-left: 2em;
  font-size: 1.5em;
  color: #3944a8;
}

.suit-sherwani a {
  text-decoration: none;
}

@media only screen and (min-width: 700px) {
  .suit-sherwani img {
    width: 35vw;
  }

  .suit-sherwani {
    font-size: 3em;
  }

  .suit-sherwani div {
    justify-content: space-around;
  }
}
