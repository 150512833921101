@media only screen and (min-width: 700px) {
  .dress-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .dress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #eeeeee;
    margin: 2em 0;
    padding: 2em;
    border-radius: 10px;
    position: relative;
  }

  .dress > img {
    width: 20em;
  }
}

a {
  display: block;
  text-decoration: none;
}

@media only screen and (max-width: 700px) {
  .dress img {
    width: 70vw;
  }

  .dress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .dress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #eeeeee;
    width: 80vw;
    margin: 2em 0;
    padding: 2em;
    border-radius: 10px;
    position: relative;
  }
}

h3 {
  font-size: 1.7em;
  color: #333333;
  text-align: center;
}
