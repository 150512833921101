header {
  text-align: center;
  padding: 2em 1em;
  background-color: #151515;
}

header > h1 {
  font-size: 2.5em;
  color: #eeeeee;
  margin-bottom: 0.5em;
}

header > h4 {
  font-size: 1.3em;
  color: #9b9b9b;
  font-weight: 400;
  margin-bottom: 0.5em;
}

.contact {
  display: flex;
  justify-content: center;
  margin-top: 2em;
}

button {
  background-color: #efefef;
  margin: 0 0.5em;
  font-size: 1.1em;
  padding: 0.5em 0.8em;
  border-radius: 10px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

button:hover {
  cursor: pointer;
}

@media only screen and (min-width: 700px) {
  header > h1 {
    font-size: 4em;
    margin-bottom: 0.2em;
  }

  br {
    display: none;
  }
}
